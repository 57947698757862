@use "sass:map";

.cid-table-container {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

.cid-table.mat-mdc-table {
  background-color: map.get($background, table);
  border-spacing: 0;
  border-collapse: separate;
  border: 1px solid map.get($background, border);
  border-radius: 16px;
  box-shadow: $box-shadow;

  &.cid-table_compact {
    .mat-mdc-header-row,
    .mat-mdc-row {
      height: 32px;
    }

    .mat-mdc-row {
      font-weight: 400;
    }
  }

  .mat-mdc-header-row {
    th {
      &:first-child {
        border-top-left-radius: 16px;
      }
      &:last-child {
        border-top-right-radius: 16px;
      }
    }
  }

  .mat-mdc-row {
    &:last-child {
      td {
        &:first-child {
          border-bottom-left-radius: 16px;
        }
        &:last-child {
          border-bottom-right-radius: 16px;
        }
      }
    }
  }

  .mdc-data-table__cell,
  .mdc-data-table__header-cell {
    padding: 0 $space-medium;
  }

  .mat-mdc-header-row {
    height: 44px;
  }

  .mat-mdc-row {
    height: 58px;
    font-size: 0.875rem;
    line-height: 17px;
    color: map.get($foreground, text);
    font-weight: 500;
  }

  .mat-mdc-header-cell {
    color: map.get($foreground, table-header);
    font-size: 0.875rem;
    line-height: 17px;
    font-weight: 500;

    .mat-sort-header-sorted {
      color: map.get($foreground, table-header-sorted);
    }
  }
}
