@use "sass:map";
@use "@angular/material" as mat;

$my-typography: mat.m2-define-typography-config(
  $font-family: $regular-font-family,
  $headline-1:
    mat.m2-define-typography-level(
      $font-family: $regular-font-family,
      $font-size: 7rem,
    ),
  $headline-2:
    mat.m2-define-typography-level(
      $font-family: $regular-font-family,
      $font-size: 3.5rem,
    ),
  $headline-3:
    mat.m2-define-typography-level(
      $font-family: $regular-font-family,
      $font-size: 2.813rem,
      $line-height: 48px,
    ),
  $headline-4:
    mat.m2-define-typography-level(
      $font-family: $regular-font-family,
      $font-size: 2.125rem,
      $line-height: 40px,
    ),
  $headline-5:
    mat.m2-define-typography-level(
      $font-family: $regular-font-family,
      $font-size: 1.5rem,
      $line-height: 29px,
    ),
  $headline-6:
    mat.m2-define-typography-level(
      $font-family: $regular-font-family,
      $font-size: 1.25rem,
      $line-height: 24px,
    ),
  $subtitle-1:
    mat.m2-define-typography-level(
      $font-family: $regular-font-family,
      $font-size: 1.125rem,
      $line-height: 28px,
    ),
  $subtitle-2:
    mat.m2-define-typography-level(
      $font-family: $regular-font-family,
      $font-size: 1.125rem,
      $line-height: 24px,
    ),
  $body-2:
    mat.m2-define-typography-level(
      $font-size: 1rem,
    ),
  $body-1:
    mat.m2-define-typography-level(
      $font-size: 1rem,
    ),
  $button:
    mat.m2-define-typography-level(
      $font-size: 1rem,
    ),
);

.cid-header-1 {
  color: map.get($foreground, text);
  font-size: 1.5rem;
  line-height: 29px;
  font-weight: 500;
}

.cid-header-2 {
  color: map.get($foreground, text);
  font-size: 1.25rem;
  line-height: 24px;
  font-weight: bold;
}

.cid-header-3 {
  color: map.get($foreground, text);
  font-size: 1.25rem;
  line-height: 24px;
  font-weight: 500;
}

.cid-body-1 {
  color: map.get($foreground, text);
  font-size: 1rem;
  line-height: 19px;
  font-weight: 500;
}

.cid-body-2 {
  color: map.get($foreground, secondary-text);
  font-size: 0.875rem;
  line-height: 17px;
  font-weight: 500;

  &.cid-color-accent {
    color: rgba(map.get($cid-accent-palette, 500), 0.5);
  }
  &.cid-color-warn {
    color: rgba(map.get($cid-warn-palette, 500), 0.5);
  }
}

.cid-body-3 {
  color: map.get($foreground, text);
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
}

.cid-body-4 {
  color: map.get($foreground, secondary-text);
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;

  &.cid-color-accent {
    color: rgba(map.get($cid-accent-palette, 500), 0.5);
  }
  &.cid-color-warn {
    color: rgba(map.get($cid-warn-palette, 500), 0.5);
  }
}

.cid-font-bold {
  font-weight: bold;
}

.cid-font-medium {
  font-weight: 500;
}

.cid-font-normal {
  font-weight: normal;
}

.cid-color-accent {
  color: map.get($cid-accent-palette, 500);
}

.cid-color-warn {
  color: map.get($cid-warn-palette, 500);
}

.cid-primary-text-color {
  color: map.get($foreground, text);
}

.cid-secondary-text-color {
  color: map.get($foreground, secondary-text);
}
