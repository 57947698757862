@use "sass:map";

.cid-card {
  width: 100%;
  background: map.get($background, card);
  padding: $space-x-small;
  border-radius: 16px;
  box-shadow: $box-shadow;
  border: 1px solid map.get($background, border);

  &.cid-card_grey {
    background: map.get($background, card-grey);
  }

  &.cid-card_br-24 {
    border-radius: 24px;
  }

  &.cid-card_large {
    padding: $space-large;
  }
}
