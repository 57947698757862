@use "sass:map";

.cid-tag {
  display: inline-flex;
  align-items: center;
  border-radius: 12px;
  font-size: 0.875rem;
  line-height: 17px;
  font-weight: bold;
  height: 24px;
  padding: $space-xx-small $space-small;
  background: map.get($background, tag);
  color: map.get($foreground, tag);

  &.cid-tag_small {
    font-size: 12px;
    line-height: 14px;
  }

  &.cid-tag_warn {
    background: rgba(map.get($cid-warn-palette, 500), 0.16);
    color: map.get($cid-warn-palette, 500);
  }
}
