@use "sass:map";

.cid-nav-tabs.mat-mdc-tab-nav-bar {
  background: map.get($background, tabs);
  box-shadow: $box-shadow;
  border-bottom: 1px solid map.get($background, border);
  padding: 0 $space-large;

  .mat-mdc-tab-link,
  .mat-mdc-tab-link:hover,
  .mat-mdc-tab-link:focus {
    color: map.get($foreground, tab);
    font-size: 1rem;
    line-height: 19px;
    font-weight: 500;
    padding: 0 $space-medium;
    min-width: auto;

    &.mdc-tab--active {
      color: map.get($foreground, tab-active);
    }

    .mdc-tab-indicator__content--underline {
      border-color: map.get($background, tab-underline);
      border-top-width: 3px;
    }

    .mdc-tab__ripple {
      display: none;
    }

    .mdc-tab__text-label {
      padding-top: 4px;
      letter-spacing: normal;
    }
  }
}
