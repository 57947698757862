// Margin spacing
.cid-m-none {
  margin: $space-none !important;
}
.cid-m-xxx-small {
  margin: $space-xxx-small !important;
}
.cid-m-xx-small {
  margin: $space-xx-small !important;
}
.cid-m-x-small {
  margin: $space-x-small !important;
}
.cid-m-small {
  margin: $space-small !important;
}
.cid-m-medium {
  margin: $space-medium !important;
}
.cid-m-large {
  margin: $space-large !important;
}
.cid-m-x-large {
  margin: $space-x-large !important;
}
.cid-m-xx-large {
  margin: $space-xx-large !important;
}
.cid-m-xxx-large {
  margin: $space-xxx-large !important;
}

// Margin-left spacing
.cid-ml-none {
  margin-left: $space-none !important;
}
.cid-ml-xxx-small {
  margin-left: $space-xxx-small !important;
}
.cid-ml-xx-small {
  margin-left: $space-xx-small !important;
}
.cid-ml-x-small {
  margin-left: $space-x-small !important;
}
.cid-ml-small {
  margin-left: $space-small !important;
}
.cid-ml-medium {
  margin-left: $space-medium !important;
}
.cid-ml-large {
  margin-left: $space-large !important;
}
.cid-ml-x-large {
  margin-left: $space-x-large !important;
}
.cid-ml-xx-large {
  margin-left: $space-xx-large !important;
}
.cid-ml-xxx-large {
  margin-left: $space-xxx-large !important;
}

// Margin-right spacing
.cid-mr-none {
  margin-right: $space-none !important;
}
.cid-mr-xxx-small {
  margin-right: $space-xxx-small !important;
}
.cid-mr-xx-small {
  margin-right: $space-xx-small !important;
}
.cid-mr-x-small {
  margin-right: $space-x-small !important;
}
.cid-mr-small {
  margin-right: $space-small !important;
}
.cid-mr-medium {
  margin-right: $space-medium !important;
}
.cid-mr-large {
  margin-right: $space-large !important;
}
.cid-mr-x-large {
  margin-right: $space-x-large !important;
}
.cid-mr-xx-large {
  margin-right: $space-xx-large !important;
}
.cid-mr-xxx-large {
  margin-right: $space-xxx-large !important;
}

// Margin-top spacing
.cid-mt-none {
  margin-top: $space-none !important;
}
.cid-mt-xxx-small {
  margin-top: $space-xxx-small !important;
}
.cid-mt-xx-small {
  margin-top: $space-xx-small !important;
}
.cid-mt-x-small {
  margin-top: $space-x-small !important;
}
.cid-mt-small {
  margin-top: $space-small !important;
}
.cid-mt-medium {
  margin-top: $space-medium !important;
}
.cid-mt-large {
  margin-top: $space-large !important;
}
.cid-mt-x-large {
  margin-top: $space-x-large !important;
}
.cid-mt-xx-large {
  margin-top: $space-xx-large !important;
}
.cid-mt-xxx-large {
  margin-top: $space-xxx-large !important;
}

// Margin-bottom spacing
.cid-mb-none {
  margin-bottom: $space-none !important;
}
.cid-mb-xxx-small {
  margin-bottom: $space-xxx-small !important;
}
.cid-mb-xx-small {
  margin-bottom: $space-xx-small !important;
}
.cid-mb-x-small {
  margin-bottom: $space-x-small !important;
}
.cid-mb-small {
  margin-bottom: $space-small !important;
}
.cid-mb-medium {
  margin-bottom: $space-medium !important;
}
.cid-mb-large {
  margin-bottom: $space-large !important;
}
.cid-mb-x-large {
  margin-bottom: $space-x-large !important;
}
.cid-mb-xx-large {
  margin-bottom: $space-xx-large !important;
}
.cid-mb-xxx-large {
  margin-bottom: $space-xxx-large !important;
}

// Padding spacing
.cid-p-none {
  padding: $space-none !important;
}
.cid-p-xxx-small {
  padding: $space-xxx-small !important;
}
.cid-p-xx-small {
  padding: $space-xx-small !important;
}
.cid-p-x-small {
  padding: $space-x-small !important;
}
.cid-p-small {
  padding: $space-small !important;
}
.cid-p-medium {
  padding: $space-medium !important;
}
.cid-p-large {
  padding: $space-large !important;
}
.cid-p-x-large {
  padding: $space-x-large !important;
}
.cid-p-xx-large {
  padding: $space-xx-large !important;
}
.cid-p-xxx-large {
  padding: $space-xxx-large !important;
}

// Padding left
.cid-pl-none {
  padding-left: $space-none !important;
}
.cid-pl-xxx-small {
  padding-left: $space-xxx-small !important;
}
.cid-pl-xx-small {
  padding-left: $space-xx-small !important;
}
.cid-pl-x-small {
  padding-left: $space-x-small !important;
}
.cid-pl-small {
  padding-left: $space-small !important;
}
.cid-pl-medium {
  padding-left: $space-medium !important;
}
.cid-pl-large {
  padding-left: $space-large !important;
}
.cid-pl-x-large {
  padding-left: $space-x-large !important;
}
.cid-pl-xx-large {
  padding-left: $space-xx-large !important;
}
.cid-pl-xxx-large {
  padding-left: $space-xxx-large !important;
}

// Padding right
.cid-pr-none {
  padding-right: $space-none !important;
}
.cid-pr-xxx-small {
  padding-right: $space-xxx-small !important;
}
.cid-pr-xx-small {
  padding-right: $space-xx-small !important;
}
.cid-pr-x-small {
  padding-right: $space-x-small !important;
}
.cid-pr-small {
  padding-right: $space-small !important;
}
.cid-pr-medium {
  padding-right: $space-medium !important;
}
.cid-pr-large {
  padding-right: $space-large !important;
}
.cid-pr-x-large {
  padding-right: $space-x-large !important;
}
.cid-pr-xx-large {
  padding-right: $space-xx-large !important;
}
.cid-pr-xxx-large {
  padding-right: $space-xxx-large !important;
}

// Padding top
.cid-pt-none {
  padding-top: $space-none !important;
}
.cid-pt-xxx-small {
  padding-top: $space-xxx-small !important;
}
.cid-pt-xx-small {
  padding-top: $space-xx-small !important;
}
.cid-pt-x-small {
  padding-top: $space-x-small !important;
}
.cid-pt-small {
  padding-top: $space-small !important;
}
.cid-pt-medium {
  padding-top: $space-medium !important;
}
.cid-pt-large {
  padding-top: $space-large !important;
}
.cid-pt-x-large {
  padding-top: $space-x-large !important;
}
.cid-pt-xx-large {
  padding-top: $space-xx-large !important;
}
.cid-pt-xxx-large {
  padding-top: $space-xxx-large !important;
}

// Padding bottom
.cid-pb-none {
  padding-bottom: $space-none !important;
}
.cid-pb-xxx-small {
  padding-bottom: $space-xxx-small !important;
}
.cid-pb-xx-small {
  padding-bottom: $space-xx-small !important;
}
.cid-pb-x-small {
  padding-bottom: $space-x-small !important;
}
.cid-pb-small {
  padding-bottom: $space-small !important;
}
.cid-pb-medium {
  padding-bottom: $space-medium !important;
}
.cid-pb-large {
  padding-bottom: $space-large !important;
}
.cid-pb-x-large {
  padding-bottom: $space-x-large !important;
}
.cid-pb-xx-large {
  padding-bottom: $space-xx-large !important;
}
.cid-pb-xxx-large {
  padding-bottom: $space-xxx-large !important;
}

// Gap
.cid-gap-none {
  gap: $space-none !important;
}
.cid-gap-xxx-small {
  gap: $space-xxx-small !important;
}
.cid-gap-xx-small {
  gap: $space-xx-small !important;
}
.cid-gap-x-small {
  gap: $space-x-small !important;
}
.cid-gap-small {
  gap: $space-small !important;
}
.cid-gap-medium {
  gap: $space-medium !important;
}
.cid-gap-large {
  gap: $space-large !important;
}
.cid-gap-x-large {
  gap: $space-x-large !important;
}
.cid-gap-xx-large {
  gap: $space-xx-large !important;
}
.cid-gap-xxx-large {
  gap: $space-xxx-large !important;
}
