@use "sass:map";
@import "variables";
@import "reset";
@import "fonts";
@import "theme";
@import "utils";
@import "spacing";
@import "components";

* {
  box-sizing: border-box;
}

html {
  font-size: 14px;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: $regular-font-family;
  background: map.get($background, background);
  min-width: 1280px;
}
