@use "sass:map";

input.cid-form-field__control {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  background-color: map.get($background, input);
  border: 1px solid map.get($background, border);
  border-radius: 8px;
  font-size: 0.875rem;
  padding: $space-x-small $space-small;
}
