@use "sass:map";

.cid-chips {
  display: flex;
  position: relative;

  .cid-chip {
    display: flex;
    align-items: center;
    height: 24px;
    background: map.get($background, chips);
    color: map.get($foreground, chips);
    font-size: 12px;
    line-height: 14px;
    font-weight: bold;
    padding: $space-xx-small;

    &:first-child {
      padding-left: $space-x-small;
      border-radius: 8px 0 0 8px;
    }

    &:last-child {
      padding-right: $space-x-small;
      border-radius: 0 8px 8px 0;
    }

    & + .cid-chip {
      border-left: 1px solid map.get($background, chips-divider);
    }
  }
}
