@use "sass:map";

.cid-select.ng-select {
  background-color: map.get($background, select);
  border: 1px solid map.get($background, border);
  border-radius: 16px;
  box-shadow: $box-shadow;
  padding: $space-small $space-medium;
  color: map.get($foreground, select);

  &.ng-select-opened {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .ng-select-container {
    min-height: auto;

    &::after {
      content: none;
    }

    .ng-value {
      font-size: 1.5rem;
      line-height: 25.5px;
      font-weight: 500;
    }

    .ng-input {
      font-size: 1.5rem;
      line-height: 25.5px;
      font-weight: 500;
      bottom: 4px;
    }

    .ng-value-container {
      padding-bottom: 0;

      .ng-placeholder {
        color: map.get($foreground, select-placeholder);
        font-size: 1rem;
        font-weight: 500;
      }
    }

    .ng-arrow-wrapper {
      .ng-arrow {
        color: map.get($foreground, select-arrow);
      }
    }
  }

  .ng-dropdown-panel {
    box-shadow: $box-shadow;
    outline: 1px solid map.get($background, border);
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;

    &.ng-select-bottom {
      top: 100%;
    }

    .ng-option {
      color: map.get($foreground, select-option);
      font-weight: 500;
      min-height: 40px;
      line-height: 40px;

      &:last-child {
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
      }

      &.ng-option-selected {
        color: map.get($foreground, select-option-selected);
        background: map.get($background, select-option);
      }
    }
  }
}
