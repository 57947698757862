@use "sass:map";

.cid-form-field {
  position: relative;
  margin-bottom: $space-large;

  &.cid-has-error {
    .cid-form-field__control {
      border-color: map.get($cid-warn-palette, 800);
    }
  }

  .cid-form-field__label {
    display: block;
    margin-bottom: $space-xx-small;
    font-size: 0.875rem;
    line-height: 17px;
    color: map.get($foreground, label);
  }

  .cid-form-field__error {
    display: block;
    font-size: 12px;
    line-height: 14px;
    // should be same as line-height
    margin-bottom: -14px;
    color: map.get($foreground, error-text);
  }
}
