@font-face {
  font-family: "FKGrotesk";
  src: url("fonts/FKGrotesk-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "FKGrotesk";
  src: url("fonts/FKGrotesk-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'FKGroteskNeueTrial';
  src: url('fonts/FKGroteskNeueTrial-Regular.ttf') format("truetype");
}

@font-face {
  font-family: 'FKGroteskNeueTrial';
  src: url('fonts/FKGroteskNeueTrial-Medium.ttf') format("truetype");
  font-style: normal;
  font-weight: 500;
}
