@use "sass:map";

.cid-divider {
  display: block;
  border-top: 1px solid map.get($background, divider);
}

.cid-vertical-divider {
  display: block;
  border-right: 1px solid map.get($background, divider);
}
