@use "sass:map";

.cid-button-toggle-group.mat-button-toggle-group {
  border: 1px solid map.get($background, border);
  border-radius: 8px;
  height: 32px;

  &.cid-button-toggle-group_light {
    .mat-button-toggle {
      background-color: map.get($background, light-button-toggle);
    }
  }

  &.cid-button-toggle-group_mini {
    height: 24px;

    .mat-button-toggle {
      font-size: 12px;
      line-height: 14px;

      .mat-button-toggle-label-content {
        padding: $space-none $space-medium;
      }
    }
  }

  &.cid-button-toggle-group_large {
    height: 72px;
    border-radius: 16px;

    .mat-button-toggle {
      &.mat-button-toggle-checked {
        button {
          border-radius: 16px;
        }
      }

      .mat-button-toggle-label-content {
        padding: $space-x-small $space-medium;
      }

      .mat-button-toggle-focus-overlay {
        border-radius: 16px;
      }
    }
  }

  .mat-button-toggle {
    font-size: 0.875rem;
    line-height: 17px;
    font-weight: 500;
    background-color: map.get($background, button-toggle);
    border: 0 !important;

    &.mat-button-toggle-disabled {
      background-color: map.get($background, button-toggle);

      .mat-button-toggle-button {
        cursor: no-drop;
      }
    }

    &.mat-button-toggle-checked {
      button {
        background-color: map.get($background, button-toggle-selected);
        border-radius: 8px;
        box-shadow: $box-shadow;
        color: map.get($foreground, button-toggle-selected);
      }
    }

    button {
      color: map.get($foreground, button-toggle);
      height: 100%;
    }

    .mat-button-toggle-focus-overlay {
      border-radius: 8px;
    }

    .mat-button-toggle-label-content {
      align-items: center;
      display: flex;
      line-height: normal;
      height: 100%;
      padding: $space-none $space-large;
    }
  }
}
