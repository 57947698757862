@use "sass:map";
@import "palettes";

$regular-font-family: FKGrotesk, sans-serif;

// Spacing system
$space-none: 0;
$space-xxx-small: 0.125rem; // 2px
$space-xx-small: 0.25rem; // 4px
$space-x-small: 0.5rem; // 8px
$space-small: 0.75rem; // 12px
$space-medium: 1rem; // 16px
$space-large: 1.5rem; // 24px
$space-x-large: 2rem; // 32px
$space-xx-large: 2.5rem; // 40px
$space-xxx-large: 3rem; // 48px

// Colors
$background: (
  background: #f4f4f4,
  // color in design rgba(255, 255, 255, 0.8)
  light-background: #fdfdfd,
  sidenav: #ffffff,
  // color in design rgba(255, 255, 255, 0.8)
  header: #fdfdfd,
  sidenav-active-link: map.get($cid-warn-palette, 500),
  sidenav-active-link-border: map.get($cid-warn-palette, 900),
  border: map.get($cid-primary-palette, 50),
  divider: map.get($cid-primary-palette, 50),
  box-shadow: rgba(0, 0, 0, 0.06),
  card: #ffffff,
  card-grey: #e7e7e7,
  chips: #e5e5e5,
  chips-divider: #ffffff,
  default-logo: #d8d8d8,
  // color in design rgba(255, 255, 255, 0.8)
  tabs: #fdfdfd,
  tab-underline: map.get($cid-warn-palette, 500),
  table: #ffffff,
  button: #ffffff,
  button-toggle: rgba(0, 0, 0, 0.08),
  light-button-toggle: #ededed,
  button-toggle-selected: #ffffff,
  fab-mini-button: #ffffff,
  select: #ffffff,
  select-option: #ffffff,
  datepicker: #ffffff,
  progress-bar: map.get($cid-primary-palette, 300),
  progress-bar-primary: map.get($cid-accent-palette, 500),
  progress-bar-secondary: map.get($cid-warn-palette, 500),
  progress-bar-primary-outline: #ffffff,
  input: #ffffff,
  tag: rgba(0, 0, 0, 0.08),
);

$foreground: (
  text: #000000,
  secondary-text: rgba(0, 0, 0, 0.5),
  disabled-text: #c7c7c7,
  label: #000000,
  error-text: map.get($cid-warn-palette, 800),
  icon-button: #7f7f7f,
  fab-mini-button: #7f7f7f,
  app-switcher: #18191B,
  app-switcher-hover: #000000,
  app-switcher-active: map.get($cid-warn-palette, 800),
  sidenav-link: #000000,
  sidenav-link-icon: rgba(24, 25, 27, 0.5),
  sidenav-active-link: map.get($cid-warn-palette, contrast, 500),
  chips: #7f7f7f,
  default-logo: #ffffff,
  button: map.get($cid-primary-palette, 800),
  button-toggle: rgba(0, 0, 0, 0.5),
  button-toggle-selected: #000000,
  tab: rgba(0, 0, 0, 0.5),
  tab-active: #000000,
  table-header: map.get($cid-primary-palette, 300),
  table-header-sorted: #000000,
  select: #000000,
  select-placeholder: rgba(0, 0, 0, 0.5),
  select-option: #000000,
  select-option-selected: rgba(0, 0, 0, 0.3),
  select-arrow: rgba(0, 0, 0, 0.5),
  tag: rgba(map.get($cid-primary-palette, 800), 0.5),
);

// Utils
$header-height: 56px;
$box-shadow: 0 1px 4px 0 map.get($background, box-shadow);
