@use "sass:map";

.cid-button {
  &.mdc-button {
    background-color: map.get($background, button);
    color: map.get($foreground, button);
    font-size: 0.875rem;
    line-height: 17px;
    padding: $space-none $space-large;
    border-radius: 8px;
    height: 40px;
    box-shadow: $box-shadow;

    &.mat-warn {
      &.mat-mdc-outlined-button,
      &.mat-mdc-unelevated-button {
        border: 1px solid map.get($cid-warn-palette, 900);
      }
    }

    &.cid-button_small {
      height: 32px;
      padding: $space-none $space-medium;
    }

    .mdc-button__ripple {
      border-radius: 7px;
    }
  }

  &.mdc-icon-button {
    &.cid-button_small {
      --mdc-icon-button-state-layer-size: 12px;

      mat-icon {
        width: 12px;
        height: 12px;
      }
    }

    &.cid-button_without-ripple {
      .mat-ripple,
      .mat-mdc-button-touch-target,
      .mat-mdc-focus-indicator {
        display: none;
      }
    }

    .mat-icon {
      color: map.get($foreground, icon-button);
    }
  }

  &.mdc-fab--mini {
    width: 20px;
    height: 20px;
    background-color: map.get($background, fab-mini-button);
    border: 1px solid map.get($background, border);
    box-shadow: $box-shadow !important;

    &.cid-button__square {
      border-radius: 4px !important;
    }

    .mat-mdc-button-persistent-ripple,
    .mat-mdc-button-ripple,
    .mat-mdc-button-touch-target {
      display: none;
    }
    .mat-icon {
      height: 14px;
      width: 14px;
      font-size: 14px;
      color: map.get($foreground, fab-mini-button);
    }
  }
}
